/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Tabbar from 'vux/src/components/tabbar/tabbar';
import TabbarItem from 'vux/src/components/tabbar/tabbar-item';
export default {
  name: 'Home',
  components: {
    Tabbar: Tabbar,
    TabbarItem: TabbarItem
  },
  data: function data() {
    return {
      transitionName: 'slide-right',
      navData: [],
      tabData: [//尾部导航数组
      {
        icon: '#icon-weixuanzhongshouyexin',
        iconActive: '#icon-xuanzhongshouyexin',
        iconId: '9001'
      }, {
        icon: '#icon-richeng',
        iconActive: '#icon-richeng-',
        iconId: '9002'
      }, {
        icon: '#icon-tongxunlu',
        iconActive: '#icon-tongxunlu-',
        iconId: '9003'
      }, {
        icon: '#icon-weixuanzhongwodexin',
        iconActive: '#icon-xuanzhongwodexin',
        iconId: '9004'
      }],
      selected: this.$utils.Store.getItem('selected') ? +this.$utils.Store.getItem('selected') : 0 //选中的导航

    };
  },
  computed: {},
  watch: {
    $route: function $route(newVal) {
      this.selected = this.navData.findIndex(function (navInfo) {
        return newVal.path === navInfo.iconUrlId;
      }) || 0;

      if (newVal.path.indexOf('My') > -1) {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    }
  },
  methods: {
    getFunIcon: function getFunIcon(value) {
      var _iterator = _createForOfIteratorHelper(this.tabData),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (item.iconId === value) {
            return item;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    getHomeMenu: function getHomeMenu() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Home.getHomeMenu({
        companyId: 0,
        userId: this.$utils.Store.getItem('userSerial'),
        spareCol2: 9
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.loadHide();

          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.navData = data;

          _this.$utils.Store.setLocalItem('menuHome', JSON.stringify(data));

          _this.$utils.Tools.loadHide();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    navClick: function navClick(value, index) {
      if (value === '' || value === null) {
        this.$utils.Tools.toastShow('功能暂未开放');
      } else {
        this.$utils.Store.setItem('selected', index);
        this.$router.push(value);
      }
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.navData = JSON.parse(this.$utils.Store.getLocalItem('menuHome'));
    this.getHomeMenu();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};